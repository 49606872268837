import "./styles.css";
import "@hotwired/turbo";

// go find all those lazy css files and transform them
function transformStyleLinks() {
  const els = document.querySelectorAll("link[as=style]");
  for (const el of els) {
    (el as HTMLLinkElement).rel = "stylesheet";
  }
}

transformStyleLinks();
